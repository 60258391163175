'use client';
import React, { useState } from 'react';
import { MimaButton, MimaInput, MimaText, MimaToastUtil } from '@/components';
import styles from '@/assets/styles/pages/partnership/PartnerOnboarding.module.scss';
import { useRouter } from 'next/navigation';
import constant from '@/utils/constant';
import { z } from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PartnerController, UserController } from '@/controllers';
import { partnerStore, userStore } from '@/stores';
import { AuthOnboardingShell } from '../_components';

const Login = () => {
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  const validationSchema = z.object({
    email: z.string().trim().email('Enter a valid Email').min(2, 'Email Address is required'),
    password: z.string().min(3, 'Password is required'),
  });

  const goToSignup = () => {
    router.push(constant.Routes.SignUp);
  };

  const goToForgotPassword = () => {
    router.push(constant.Routes.ForgetPassword);
  };

  type ILogin = z.infer<typeof validationSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<ILogin>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (values: ILogin) => {
    setLoading(true);
    const payload = {
      userEmailMobile: values.email,
      password: values.password,
      accountType: 'Business',
    };

    const { status, data, errorMessage } = await UserController.login(payload);
    if (status === constant.Success) {
      setLoading(false);
      return router.push(constant.Routes.Dashboard);
    } else if (data?.onbordingDone === false) {
      setLoading(false);
      userStore.setOtpType(constant.OtpType.ONBOARDING);
      return router.push(constant.Routes.Otp);
    } else {
      setLoading(false);
      MimaToastUtil.error({
        message: errorMessage,
      });
    }
  };

  return (
    <AuthOnboardingShell>
      <div className={styles.logInForm}>
        <div className={styles.logintext__center}>
          <MimaText color="var(--color-dark)" variant="subtitleBold">
            Log In
          </MimaText>

          <p className={styles.logintext__p}>
            Don’t have an account?{' '}
            <span className={styles.clickable} onClick={goToSignup}>
              Sign Up{' '}
            </span>
            for free
            <br /> it will take less than a minute
          </p>
        </div>
        <form className={styles.loginform} onSubmit={handleSubmit(onSubmit)}>
          <MimaInput
            labelTitle="Email address"
            type="text"
            placeholder="Enter your email address"
            id="email"
            name="email"
            error={errors.email}
            touched={touchedFields.email}
            control={control}
          />
          <MimaInput
            labelTitle="Password"
            type="text"
            placeholder="Enter your password"
            id="password"
            name="password"
            passwordIcon
            error={errors.password}
            touched={touchedFields.password}
            control={control}
          />
          <MimaButton title="Login" type="submit" loading={loading} variant="colorable" buttonColor="var(--color-dark)" />
        </form>
        <div className={`${styles.logintext__p} ${styles.mt_40}`}>
          Forgot your password?
          <span className={styles.clickable} onClick={goToForgotPassword}>
            {' '}
            Click Here
          </span>
        </div>
      </div>
    </AuthOnboardingShell>
  );
};

export default Login;
