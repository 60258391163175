import React, { ReactNode } from 'react';
import constant from '@/utils/constant';
import { FashWhiteLogo } from '@/assets/img/ImgList';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { MimaText } from '@/components';
import styles from '@/assets/styles/pages/auth/AuthOnboarding.module.scss';

interface IAuthOnboardingShell {
  children: ReactNode;
}
const AuthOnboardingShell: React.FC<IAuthOnboardingShell> = ({ children }) => {
  const router = useRouter();

  const goToLanding = () => {
    router.push(constant.Routes.Home);
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Image src={FashWhiteLogo} className={styles.fashIcon} alt="Fash Logo" onClick={goToLanding} />
        <div className={styles.welcome}>
          <MimaText variant="h4Bold" align="center">
            Unleash your creativity, Sell with ease.
          </MimaText>
        </div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default AuthOnboardingShell;
